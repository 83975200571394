@import 'breakpoint-sass';

.App {
    text-align: center;   
  } 

  .cta-btn {
    display: inline-block;
    position: relative;
    padding: 0.4rem;
    font-size:1.6rem;
    font-weight: bold;
    line-height: .75;
    z-index: 1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
    @include breakpoint(0px 600px){
      font-size: .5rem;
      line-height: .35;  }       
  }

  .cta-btn {
    @include breakpoint(600px 900px){
      font-size: .9rem;
      line-height: .40;  }}  
      
  .cta-btn {
     @include breakpoint(900px 1200px){
       font-size: 1.2rem;
       line-height: .55;  }}      
          
  
  .cta-btn::after {
    content: '';
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
  
  .cta-btn--hero {
    background-image: linear-gradient(
      135deg,
      #753a88 0%,
      #cc2b5e 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid transparent;
    border-image: linear-gradient(
      135deg,
      #753a88 0%,
      #cc2b5e 100%
    );
    border-image-slice: 1;
  }
  
  .cta-btn--hero::after {
      background-image: linear-gradient(
        135deg,
        #753a88 0%,
        #cc2b5e 100%
      );
  }
  
    .cta-btn--hero:hover {
      -webkit-text-fill-color:white;
      text-decoration: none;    
      }  
  
      .cta-btn--hero:hover::after{
        width:100%;
      }
  
  .prices{
    text-align:center;
  }

  .startup{
    font-size: 25px;
    text-align: center;
  }
  
  .pri{
    font-size: 30px;
    font-weight: bold;
  }
  
  .number{
    font-size:45px;
  } 
  
  .App-header{
    background-image:url(./assets/myBack.jpg);  
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;   
    justify-content: center;  
    color: #c46fe0;  
    @include breakpoint(0px 600px){
      min-height:40vh;
    }}   

    .App-header{
      @include breakpoint(600px 900px){
        min-height:60vh;}}

    .App-header{
          @include breakpoint(900px 1200px){
            min-height:80vh;}}
      
  .pic{
    width:75px;
    height:100px;
    @include breakpoint(0px 600px){
      width:35px;
      height:50px;}
  } 

  .pic{
    @include breakpoint(600px 900px){
      width:50px;
      height:65px;}}

      .pic{
        @include breakpoint(900px 1200px){
          width:65px;
          height:80px;}}    
  
  .hood{ 
    text-align: left;
    font-size: 4.6rem;
    font-weight: 700;
    margin-bottom: 15rem;  
    padding-left:100px;
    @include breakpoint(0px 600px){
    text-align: left;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 5rem;  
    padding-left:10px;}
    }

    .hood{
      @include breakpoint(600px 900px){
        text-align: left;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 5rem;  
        padding-left:10px;}
    }
  
    .hood{
      @include breakpoint(900px 1200px){
        text-align: left;
        font-size: 3.3rem;
        font-weight: 700;
        margin-bottom: 5rem;  
        padding-left:10px;}
    }
    .aboutMe{
      font-size: 4.6rem;
      color:white;
      @include breakpoint(0px 600px){
        font-size: 1.6rem;
    }
  }

  .aboutMe{    
    @include breakpoint(600px 900px){
      font-size: 2.9rem;
  }
}

.aboutMe{    
  @include breakpoint(900px 1200px){
    font-size: 3.9rem;
}
}

    .about-wrapper__image{
      display: flex;
      width:100%;
      height:auto;
      align-items: center;
      justify-content: center;
     }
    
    .about-wrapper__info{
      display: flex;
      width:100%;
      height: auto;      
      justify-content: center;
      flex-direction: column;
    }
  
    .thumbnail rounded{
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      padding-left: 220px;
    }
  
    .about-wrapper__info_project{
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;    
    }
  
    .about-wrapper__info-text{
      text-align: left;
      color:white;     
      @include breakpoint(0px 600px){
        font-size: .3rem;}      
      }

      .about-wrapper__info-text{
        text-align: left;
        color:white;     
        @include breakpoint(600px 900px){
          font-size: .5rem;}      
        }

        .about-wrapper__info-text{
          text-align: left;
          color:white;     
          @include breakpoint(900px 1200px){
            font-size: .7rem;}      
          }     

    #about {
      background: #6317CA;
      background: -webkit-linear-gradient(left, #6317CA, #B975DD);
      background: -moz-linear-gradient(left, #6317CA, #B975DD);
      background: linear-gradient(to right, #6317CA, #B975DD);   
      }
  
      #project{
        background: linear-gradient(to left, #3333ff 0%, #00ccff 100%);
      }
  
      .margin-right{
        margin-left: 20px !important;
    }
  
    #contact{
      background: #6317CA;
      background: -webkit-linear-gradient(right, #6317CA, #B975DD);
      background: -moz-linear-gradient(right, #6317CA, #B975DD);
      background: linear-gradient(to left, #6317CA, #B975DD);
    }
  
    .conta{
      font-size: 3rem;
      color:white;
      @include breakpoint(0px 600px){
        font-size: 1rem;}
    }
  
    #end{
      font-size: 3.0rem;  
      background-color: rgb(90, 82, 82); 
      color:black;  
    }
  
    .f-text{
      color: darken(white, 30%);
      font-size: 1.3rem;
      @include breakpoint(0px 600px){
        font-size:.8rem;}
    }
  
    .logo{
      padding-left:50px;
    }  

   .skillset{
    @include breakpoint(0px 600px){
      height:auto;
      width:30%;
   }}   

   .skillset{
    @include breakpoint(600px 900px){
      height:auto;
      width:45%;
   }}   

   .skillset{
    @include breakpoint(900px 1200px){
      height:auto;
      width:68%;
   }}   

   .colum{
    @include breakpoint(0px 600px){
     flex:50%;
     padding:8px;  
  }}

  .colum{
    @include breakpoint(600px 900px){
     flex:50%;
     padding:8px;  
  }}

  .colum{
    @include breakpoint(900px 1200px){
     flex:50%;
     padding:8px;  
  }}

  .colum{
    @include breakpoint(1200px 1800px){
     flex:50%;
     padding:8px;  
  }}

  .colum{
    @include breakpoint(1800px 5500px){
     flex:50%;
     padding:8px;  
  }}

   h2{
    @include breakpoint(0px 600px){
     font-size: .7rem;
    }}

    h2{
      @include breakpoint(600px 900px){
       font-size: 1.0rem;
      }}

    .resume{      
      @include breakpoint(0px 600px){
        width:400px;
        height:650px;}       
    }

    .resume{      
      @include breakpoint(600px 900px){
        width:550px;
        height:750px;}       
    }

    .resume{      
      @include breakpoint(900px 1200px){
        width:850px;
        height:1000px;}       
    }


    .page{
      @include breakpoint(0px 600px){
        width:400px;
        height:650px;}      
    }

    .page{
      @include breakpoint(600px 900px){
        width:550px;
        height:750px;}      
    }    
    
    #projectsScroll{
      padding-left: 80px;      
      color: #c46fe0;  
      font-size: 55px;   
      padding-right: 80px;        
    }

    #projectsScroll:hover{
      color:purple;
    }

    #projectsScroll{    
    @include breakpoint(0px 600px){
      font-size:15px;}}

    #projectsScroll{    
        @include breakpoint(600px 900px){
          font-size:30px;}}  

          #projectsScroll{    
            @include breakpoint(900px 1200px){
              font-size:45px;}}  

    #contactScroll{
      color: #c46fe0;  
      font-size: 55px;  
    }

    #contactScroll:hover{
      color:purple;
    }

    #contactScroll{    
      @include breakpoint(0px 600px){
        font-size:15px;}}

        #contactScroll{    
          @include breakpoint(600px 900px){
            font-size:30px;}}  

            #contactScroll{    
              @include breakpoint(900px 1200px){
                font-size:45px;}}  

    #aboutScroll{
      color: #c46fe0;  
      font-size: 55px;  
    }

    #aboutScroll:hover{
      color:purple;
    }

    #aboutScroll{    
      @include breakpoint(0px 600px){
        font-size:15px;}}

        #aboutScroll{    
          @include breakpoint(600px 900px){
            font-size:30px;}}  

            #aboutScroll{    
              @include breakpoint(900px 1200px){
                font-size:45px;}}  


   
   

   